module.exports = [
  {
    "name" : "NIMBUS",
    "id" : "nimbus",
    "types": ["web"],
    "assetsDir": "projects/nimbus",
    "client": "NASA JPL + CLiMA",
    "years": "2020 – 2021",
    "role": "designer + front-end developer",
    "keywords": "data visualization, climate prediction",
    "collaborators": "Martin Bernard & Barbara Alonso",
    "description": "An open-source, interactive web-based tool for visualizing and analyzing multivariate climate simulation data.",
    "content": "This project was undertaken as a Design Lead in the Data to Discovery program, a data visualization, art and design research initiative between NASA Jet Propulsion Laboratory, ArtCenter College of Design, and Caltech).<br/><br/>As a designer and developer on this project, I worked closely with several research scientists from the Climate Modeling Alliance, headed by P.I Tapio Schneider of Caltech. I met with stakeholders on a weekly basis, using contextual inquiry as the main method of understanding each person's role and responsibilities, the challenges in their current workflow, and their unique needs. <br/><br/>I generated user interfaces designs for an interactive data visualization tool, with several rounds of design iterations to incorporate feedback we received from the team. I also took on a lot of the frontend responsibilities, using React, d3.js, and Node.js to create a high fidelity interactive prototype. I continued working on the project for an additional three months as a software UI designer and developer at Caltech.",
    "imgs": ["nimbus_1.gif"],
    "tools": "HTML5, CSS3, Javascript (React, d3.js, Node.js)",
    "urls": {

    },

  },

  {
    "name" : "Compossible Assemblages",
    "id" : "compossible-assemblages",
    "types": ["speculative"],
    "assetsDir": "projects/compossible-assemblages",
    "client": "MFA Graduate Thesis",
    "years": "2021",
    "role": "self-directed",
    "collaborators": "",
    "description": "A series of speculative, near-future interventions and provocations that renegotiate capitalistic paradigms of utilitarian infrastructure networks towards a more balanced relationship with nature and ecosystems. <br /><br /><i>MFA Graduate Thesis in Media Design Practices <br/>ArtCenter College of Design</i>",
    "content": "Submitted as graduate thesis for Media Design Practices M.F.A, ArtCenter College of Design.<br/><br/>Compossible Assemblages is a series of near-future interventions and provocations that renegotiate the capitalistic paradigms of utilitarian infrastructure networks (e.g. water, datacenters, telecommunications).<br/><br/> These design engagements examine how networks of infrastructure and the pervasiveness of computation have perpetuated the binary of humans versus wilderness, resulting in unbalanced relationships between humans and nonhuman entities. Through this work, I complicate and broaden notions of ‘ecosystems’ as being deeply intertwined with humans, considering how shifts in human behavior + thinking might occur in order for these kinds of scenarios to take shape.<br/><br/>Alongside this, I consider the multifaceted relationship that humans have with nature – specifically drawing upon the surface-level, desirable aesthetics and functional benefits that tend to be prioritized over sustaining wildlife.",
    "carouselImgs": ['hero-lpn.png', 'hero-coefficient.png', 'hero-wl.png'],
    "imgs": ['hero-lpn-diorama.png'],
    "tools": "Cinema 4D, Figma, Javascript (p5.js), Raspberry Pi",
    "url": "https://compossibleassemblages.cargo.site/",
  
  },

  {
    "name" : "Shadow Stalker (Installation)",
    "id" : "shadowstalker",
    "types": ["media art"],
    "assetsDir": "projects/shadowstalker",
    "client": "Lynn Hershman Leeson",
    "role": "interactive designer + developer",
    "years": "2019 – 2020",
    "collaborators": "with Mark Hellar",
    "exhibitions": ["Manual Override, The Shed, NYC", "Uncanny Valley, de Young Museum, SF"],
    "description": "An interactive installation that generates digital shadows of participants, displaying personal information about them retrieved from internet databases with the use of just their email addresses. <br/><br/><br/>Exhibited at:<br/>\"Manual Override\" at The Shed<br/>\"Uncanny Valley\" at de Young Museum<br/>\"Art in the Age of Anxiety\", Sharjah Art Foundation<br/>The 13th Gwangju Biennale, South Korea",
    "content": "\"Shadow Stalker is a “live” interactive installation that uses algorithms, performance and projections to make visible private Internet systems like Predictive Policing that are increasingly used by law enforcement and promote racial profiling.\" - Lynn Hershman Leeson <br/><br/>As an interactive designer + developer on the project, I worked on building the user experience for two parts of the installation. First was the entrance to the exhibit where users would enter their email; the app was built with Javascript and Node, pulling user data from an external API. I designed and developed the projection that displayed user data, blurring out the most revealing personal information. I also aided in the development of the shadow projections, which were created with Unity and Kinect, and was on site to support the live installation at The Shed, NYC for the Uncanny Valley exhibit.<br/><br/>Shadow Stalker won a 2020 Prix Ars Electronica Award of Distinction.<br/><br/><br/><br/>Exhibitions:<br/>Manual Override, The Shed, NYC (November 13, 2019 – January 12, 2020)<br/>Uncanny Valley, de Young Museum, FAMSF (February 22, 2020 – October 25, 2020)<br/>Art in the Age of Anxiety, Sharjah Art Foundation (March 21, 2020 – June 21, 2020)<br/>The 13th Gwangju Biennale, South Korea (February 26 – May 9, 2021)<br/><br/><br/><br/>(Photos courtesy of The Shed / Dan Bradica, de Young Museum)",
    "videoEmbedUrl" : ``,
    "imgs": ['shadowstalker.png'],
    "carouselImgs":["theshed-1.jpg", "deyoung.jpg", "deyoung-2.jpg"],
    "tools": "Kinect, Unity, HTML3, CSS3, Node.js, Websockets, MQTT",
    "url": "",
    
  },

  {
    "name" : "Workshop: Intro to p5.js + Tone.js",
    "id" : "bitrate",
    "types": ["web"],
    "assetsDir": "projects/bitrate",
    "client": "Google Magenta x Gray Area",
    "role": "workshop instructor",
    "years": "2020",
    "collaborators": "",
    "description": "A 3-hour introductory workshop covering the basics of audio visualization and synthesis using open-source javascript libraries p5.js and tone.js. <br/><br/>Held as part of the BitRate Machine Learning & Music Series, a hackathon hosted by Google Magenta x Gray Area.",
    "content": "Held as part of the month-long BitRate Machine Learning & Music Series hackathon hosted by Google Magenta x Gray Area. <br/><br/>I served as part of the judging committee and also taught a 3-hour introductory workshop covering the basics of audio visualization and synthesis using p5.js and tone.js. The workshop was held remotely, and was attended by 300+ participants.",
    "imgs": ['bitrate-gallery.png'],
    "tools": "p5.js, tone.js",
    "url": "",
  },
  
  {
    "name" : "Dispersal Vector Agents",
    "id" : "dispersal-vector-agents",
    "types": ["speculative"],
    "assetsDir": "projects/dispersal-vector-agents",
    "client": "MFA Design Studio II",
    "years": "2020",
    "role": "self-directed",
    "collaborators": "",
    "description": "A system design for a reimagined seed vault, where the organization, storage, and movement of seeds is facilitated by robot agents that deliver seeds based on need. Here, I imagine a plausible scenario where natural seed dispersal mechanisms are eradicated or at-risk as a result of climate change.",
    "content": "The institution of seed vaults is one attempt at preparing for widespread food shortage, which is one worst case scenario likely to come about as a result of global climate change. Seeds chosen for storage are typically those that can sustain human life (e.g., wheat, legumes, various food crop seeds). While addressing an important issue, these structures are largely designed for humans, undermining the importance of seeds within broader ecological systems. <br/><br/>Climate change disturbs interdependent relationships in nature comprised of plants, animals, and even weather systems. Seeds are an integral part of sustaining these systems; the plants that grow from them provide food and shelter for various species. At the same time, seeds also rely on these systems for their own survival.<br/><br/>In order to grow in population and over large distances, seeds require natural and biological mechanisms to ‘move.’ For example, seeds can travel via wind, from continent to continent over ocean currents, and via animals, who disperse seeds by ingestion and via their migratory paths.<br/><br/>Dispersal Vector Agents imagines a scenario where these mechanisms are eradicated or at-risk as a result of climate change. This project reconsiders seeds as active, living organisms. In this structure, the organization, storage, and movement of seeds is facilitated by a system that utilizes robot agents to deliver seeds based on need.",
    "videoEmbedUrl" : ``,
    "imgs": ['dispersal-vector-agents.png'],
    "tools": "Cinema 4D, Sketch 3",
    "url": "",

  },

  {
    "name" : "HIVE",
    "id" : "hive",
    "types": ["web"],
    "assetsDir": "projects/hive",
    "client": "MFA Creative Tech III",
    "years": "2020",
    "role": "designer + front-end developer",
    "collaborators": "with Martin Bernard, Aishwarya Janwadkar, & Alana Zhang",
    "description": "An interactive prototype for users to observe bees, whose behaviors are affected by environmental factors controlled by the user.",
    "content": "This project simulates a bee hive, represented in two views: an inner view of the hive, where one can hover over any bee to see what type of bee it is, as well as an outer view of the hive in its surrounding environment.<br/><br/>This view offers a few different ways to interact with the hive: plant flowers that bees can pollinate, change the temperature of the environment, and add pesticides to the flowers. Performing any of these interactions impacts the overall health of the bee colony.  ",
    "videoEmbedUrl" : ``,
    "imgs": ['hive.png'],
    "tools": "HTML5, CSS3, Javascript (p5.js)",
    "url": "",

  },


  {
    "name" : "City of Retextured Coexistence",
    "id" : "retextured-coexistence",
    "types": ["speculative"],
    "assetsDir": "projects/retextured-coexistence",
    "client": "MFA Design Studio I",
    "years": "2019",
    "role": "self-directed",
    "collaborators": "",
    "description": "A series of illustrated design fictions for a reimagined city, where inaccessible structures like that of oil refineries are more integrated with society.",
    "content": "This city aims to reimagine our relationship with large scale, inaccessible, corporate urban infrastructures.<br/><br/>A codependence exists: many of these infrastructures depend on the presence of natural resources to exist and function (e.g., oil, water) and depend on the consumption of such resources; we depend on the these infrastructures to supply us with resources that we rely on in our daily lives. Despite this codependence, our relationship with these spaces is that of a siloed and strained one; the possibilities for engagement are limited, controlled, and oftentimes impossible.  Envisioning alternatives is difficult, given the scale and closed nature of these infrastructures. <br/><br/>In this reimagined city, these infrastructures are separated and removed from their more industrial aesthetic, enabling us to freely augment, integrate, and superimpose. In doing so, this city aims to explore what alternative purposes these places might serve, what urban interactions they might support, and to envision a means of coexisting with these spaces.",
    "videoEmbedUrl" : ``,
    "carouselImgs": ['SITE01_OILSHRINES.png', 'SITE02_CREMATORIUM.png', 'SITE03_MEDITATION.png'],
    "imgs": ['tank-model.jpg'],
    "tools": "Cinema 4D, Sketch 3, Agisoft Metashape",
    "url": "",

  },

  {
    "name" : "RUSH v.1 – Entrance",
    "id" : "rush-v1",
    "types": ["media art"],
    "assetsDir": "projects/rush-v1",
    "client": "LEVYdance + Gray Area",
    "role": "interactive designer + developer",
    "years": "2019",
    "collaborators": "with Mark Schroeder",
    "description": "An interactive and immersive touchable tunnel entrance inviting audience members to participate in a three-part dance performance. <br/><br/>Presented by:<br/><i>LEVYdance x Gray Area<br/> Mission Theater, SF</i>",
    "content": "RUSH v.1, presented by LevyDance x Gray Area, is an experimental, immersive and participatory dance performance. <br/><br/> As an interactive designer and developer on this project, I worked with design technologist Marc Schroeder to develop an interactive and immersive touchable tunnel entrance for the event.<br/><br/>I helped plan the physical installation and determined the best mechanism for the structure holding together the scrim for rear projection. Additionally, I wired together an array of ultrasonic sensors which was connected to an Arduino and placed inside of the tunnel. The sensor data was sent to a Processing application that used the data to drive fluid simulation animations that were projected on both sides of the tunnel (using the PixelFlow library).",
    "videoEmbedUrl" : ``,
    "imgs": ['tunnel-lit.jpg'],
    "carouselImgs": ['rush-v1.png', 'Rush-Wall.jpg', 'mock.png'],
    "tools": "Processing, Arduino (ultrasonic sensors, serial communication), MadMapper",
    "url": "",

  },
  
  {
    "name" : "Perennial",
    "id" : "perennial",
    "types": ["media art"],
    "assetsDir": "projects/perennial",
    "years": "2018",
    "role": "self-directed",
    "collaborators": "",
    "exhibitions": ["Gray Area Artist Showcase 2018.1, Mission Theater, SF"],
    "description": "A 3D meditation born from daydreams, pieced together from fragments of self-reflection & self-care. A little world, a gentle vessel, a patient portal. <br/><br/>Exhibited at:<br/><i>Gray Area Artist Showcase 2018.1<br/>Mission Theater, SF</i>",
    "content": "Perennial grew during a long phase of much dedicated, much needed, self-reflection & self-care. It is a little world, a gentle vessel, a patient portal.<br/><br/>This project was exhibited as an interactive installation (using Leap Motion) at the 2018 Showcase at Gray Area Foundation for the Arts.",
    "videoEmbedUrl" : `<iframe src="https://player.vimeo.com/video/303649092?autoplay=1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" allow="autoplay;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>`,
    "imgs": ["install.png"],
    "carouselImgs": ["proto-1.png", "proto-2.png", "install-mock.png"],
    "tools": "Cinema 4D, HTML5, three.js, web audio, Leap Motion",
    "url": "https://perennial.herokuapp.com/",
  
  },

  {
    "name" : "PROPAGATOR",
    "id" : "propagator",
    "types": ["web"],
    "assetsDir": "projects/propagator",
    "years": "2018",
    "role": "self-directed",
    "collaborators": "",
    "description" : "A digital zine created by a network of peers working across various creative disciplines looking to expand their art, design, and research practices.",
    "content": "Several conversations with peers who also work within art and design disciplines revealed a shared sentiment along these lines: \“I really want to make something different from what I usually make, but I’m not sure what I want to create or where to start.\” In our work, we realized that we tend to employ processes that we learned in school or professional environments. Although these processes have undoubtedly led to work that we’re proud of, we also realized that they can feel quite formulaic and potentially limiting for our creative outputs. \“What are some ways that we can gain new insights and perspectives in our own work?\” <br/><br/>To answer this question, I decided to start a digital zine as a platform for supporting a community of peers working across creative disciplines in expanding our artistic, design, and research practices.<br/><br/>Each issue of PROPAGATOR is put together based on a loosely structured prompt that invites contributors to engage in an activity meant to be outside the norms of their creative practices and professional work.<br/><br/>In ISSUE 01, \"DREAM MACHINE,\" contributors were asked to attempt dream journaling for several weeks, to talk about their experiences with dream journaling, and to create a piece based on their entries. The format of their dream journals was not predetermined, rather, they were asked to experiment with different methods to get a feeling for what was right for them. This zine issue features contributors' works (where submissions included 3D art for the web, data visualizations, short essays, video essays, and illustrations) alongside images of their dream journals.",
    // "videoEmbedUrl" : `<iframe style="background:white; "src="https://www.propagator.xyz"></iframe>`,
    "imgs": ['propagator-ss.png'],
    "tools": "Node.js, HTML5, CSS3, React, Sketch 3",
    "url": "https://propagator.xyz",

  },
  
  {
    "name" : "The Memory Observatory",
    "id" : "memoryobservatory",
    "types": ["media art"],
    "client": "Kodak Alaris",
    "years": "2016",
    "collaborators": "",
    "role": "designer + frontend developer",
    "description" : "An immersive, interactive experience to support the launch of the new Kodak Alaris Moments app, installed and exhibited at SXSW 2016.",
    "content" : "I worked as a design technologist for a design agency, Junior, on a collaboration with Kodak Alaris and Special Guest to create the Memory Observatory at SXSW 2016.<br/><br/>This physical installation provided an experience where people were invited to share upload a photo and describe a past memory and then re-experience their memory in a kaleidoscopic room with carefully chosen smells, colors, and custom sounds.<br/><br/>In this work, I was tasked with building two React applications that could support this experience. One of the apps was a custom timer that could communicate to the installation ushers when it was time to let another visitor into the experience. This was an important component given the sequential nature of the experience; only one memory was shown in the installation at a time. An usher would talk to a visitor for a maximum of five minutes about the visitors' chosen memory and uploaded photo. They needed to be able to communicate when they were done with each visitor to the specialists working behind the scenes, so that they could begin mixing audio and crafting custom smells. The second app was a website where visitors could upload their photos to a server, which was built to support the high foot traffic of visitors at SXSW.",
    "videoEmbedUrl":`<iframe src="https://player.vimeo.com/video/157771821?byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>`,
    "tools": "React, Node.js, Twilio API",
    "imgs":["memoryobservatory_2.png"],
    // "carouselImgs" : ["memoryobservatory_1.png", "memoryobservatory_2.png"],
    "url" : "",
 
  },
  {
    "name" : "Catching Tigers & Flies",
    "id" : "chinafile",
    "types": ["web"],
    "client": "ChinaFile – Asia Society",
    "role": "creative technologist / frontend developer",
    "years": "2015",
    "description" : "An interactive, web-based data visualization tool for observing and understanding patterns of corruption across China's provinces.",
    "videoEmbedUrl" : "",
    "content" : "As the Creative Technologist in Residence at Schema Design, I worked on a collaborated with ChinaFile, a publication by Asia Society, to create an interactive tool for visualizing and understanding corruption in China.<br/><br/>When a user interacts with the visualization for the first time, they are presented with a grid of figure icons, where each icon represents an individual accused of some level of corruption and color is used to indicate that individuals' stage of investigation. When a user hovers over any individual, they are able to see that individuals' connections to others in the dataset. Clicking on an icon brings up that individual's profile and a radial visualization of their connections, as well as how they are connected (e.g., they may be family members or from the same province).<br/><br/>I worked closely with a visual designer and another web developer to build several features for this project, including the main dashboard and filtering mechanisms that would enable users to drill down as they saw fit. Additionally, I met with the client weekly to talk through important data points that they wanted to be communicated through both visual design and user interactions.",
    "carouselImgs": ["chinafile-1.png", "chinafile-4.png", "chinafile-3.png"],
    "imgs" : ["chinafile-2.png"],
    "url" : "http://anticorruption.chinafile.com",
    "tools": "HTML, CSS, Javascript (Isotope, c3.js)",

  }
];
