module.exports = [
	{
		"type" : "full",
		"imgs" : [
			{
				"img": "tenderbloom.png",
				"tools": "",
			}
		]
	},
	

	{
		"type" : "grid",
		"imgs": [
			{
				"img": "shortened.gif",
				"tools": "",
			},
			{
				"img": "aloe.png",
				"tools": "",
			},
			{
				"img": "interim.png",
				"tools": "",
			},
			{
				"img": "monstera_still.png",
				"tools": "",
			},
			{
				"img": "polar.jpg",
				"tools": "",
			},
			{
				"img": "mesh-connect.gif",
				"tools": "",
			},
		]
	}
	
];
