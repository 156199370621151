import React, { Component } from 'react';
import '../css/Project.css';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';

class Project extends Component {

  // ------------------------------------------------------
  renderInnerHtml(content) {
    return { __html: content };
  }

  // ------------------------------------------------------
  render() { 
    
    const proj = this.props.data;
    
    // Grid view
    return (
      <div className={`content-item-${ this.props.currentView }`}>
        <div className='hero-container'>
          {
            proj.id === "compossible-assemblages" && 
            <Link to="//compossibleassemblages.cargo.site" target="_blank" rel="noopener noreferrer">       
              <LazyLoad>
                <img 
                  alt={ proj.id  }
                  className="project-img-grid" 
                  src={ require(`../assets/projects/${ proj.id  }.png`).default }>
                </img>
              </LazyLoad>
            </Link> 
          }
          {
            proj.id === "dispersal-vector-agents" && 
            <Link to="//rosewater.cargo.site/Dispersal-Vector-Agents" target="_blank" rel="noopener noreferrer">       
              <LazyLoad>
                <img 
                  alt={ proj.id  }
                  className="project-img-grid" 
                  src={ require(`../assets/projects/${ proj.id  }.png`).default }>
                </img>
              </LazyLoad>
            </Link> 
          }
          {
            proj.id === "hive" && 
            <Link to="//rosewater.cargo.site/Hive" target="_blank" rel="noopener noreferrer">       
              <LazyLoad>
                <img 
                  alt={ proj.id  }
                  className="project-img-grid" 
                  src={ require(`../assets/projects/${ proj.id  }.png`).default }>
                </img>
              </LazyLoad>
            </Link> 
          }
          {
            proj.id === "retextured-coexistence" && 
            <Link to="//rosewater.cargo.site/City-of-Retextured-Coexistence" target="_blank" rel="noopener noreferrer">       
              <LazyLoad>
                <img 
                  alt={ proj.id  }
                  className="project-img-grid" 
                  src={ require(`../assets/projects/${ proj.id  }.png`).default }>
                </img>
              </LazyLoad>
            </Link> 
          }
          {
            proj.id !== "compossible-assemblages" && 
            proj.id !== "dispersal-vector-agents" && 
            proj.id !== "hive" && 
            proj.id !== "retextured-coexistence" &&
            <Link to={`/work/${ proj.id }`}>       
              <LazyLoad>
                <img 
                  alt={ proj.id  }
                  className="project-img-grid" 
                  src={ require(`../assets/projects/${ proj.id  }.png`).default }>
                </img>
              </LazyLoad>
            </Link>
          }
         
        </div>

        <div className="project-meta-container">
          <div className="upper-section">
            <Link 
              className="project-name project-link" 
              to={`/work/${ proj.id  }`} 
            >
              <span className="project-name project-link">{ proj.name }</span>
            </Link> 
            <p className="project-client">
              {
                proj.hasOwnProperty('client') ? `${ proj.client } / ` : null
              }
              { proj.years }
            </p>
            
            
          </div>
          <div className="lower-section">
            
            <p className="project-desc" dangerouslySetInnerHTML={ this.renderInnerHtml(proj.description) }>
            </p>
  
            <div>
              <div className="line-deco"></div>
              {
                proj.hasOwnProperty('role') ? 
                <p className="project-role">role: { proj.role }</p> : null
              }
              { 
                proj.hasOwnProperty('tools') ? 
                <p className="project-tools">tools: { proj.tools }</p> : null 
              }
            </div>
            
          </div> 
        </div>
      </div>
    );
  }
}

export default Project;